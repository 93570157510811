<template>
    <div>
        <v-card id="details" :loading="loading" height="100vh">
            <v-card-title ref="title">
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>
                    {{
                        !originalOrder
                            ? $vuetify.breakpoint.smAndDown
                                ? 'New PL'
                                : 'New Packing List'
                            : $vuetify.breakpoint.smAndDown
                            ? 'Edit PL'
                            : `Edit Packing List (${originalOrder.code})`
                    }}
                    - {{ project.name }}
                </h3>
                <v-btn
                    v-if="packingList.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn
                    small
                    icon
                    color="primary"
                    @click="selectScopes"
                    v-if="packingList.id"
                >
                    <v-icon>mdi-printer-pos</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                class="pt-0"
                id="virtual-scroll-table"
                :style="{ height: '81vh' }"
            >
                <v-form v-model="valid" class="py-2">
                    <v-row no-gutters class="mt-4" ref="projectSelector">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                v-model="project.name"
                                prepend-icon="mdi-account-hard-hat"
                                label="Project"
                                readonly
                                hide-details
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                ref="deliveryInfoInput"
                                v-model="deliveryInfoString"
                                hide-details
                                label="Delivery Information"
                                prepend-icon="mdi-map-marker"
                                @focus="openDeliveryInfoDialog"
                                @blur="resetFocus"
                                readonly
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="
                                            packingList.requestedShipmentDate
                                        "
                                        label="Requested Date For Shipment"
                                        prepend-icon="mdi-calendar-blank"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="packingList.requestedShipmentDate"
                                    @input="updateShipmentDate"
                                    :min="minDate"
                                >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                        >
                            <v-text-field
                                v-model="packingList.notes"
                                rows="1"
                                hide-details
                                label="Notes"
                                prepend-icon="mdi-text"
                                @blur="updateNotes"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-0">
                        <v-col
                            cols="12"
                            xl="3"
                            lg="3"
                            md="3"
                            sm="6"
                            class="pl-2"
                            v-if="createdBy"
                        >
                            <v-text-field
                                v-model="createdBy"
                                prepend-icon="mdi-account"
                                label="Created By"
                                readonly
                            />
                        </v-col>
                        <v-row
                            cols="12"
                            xl="9"
                            lg="9"
                            md="9"
                            sm="9"
                            class="py-0"
                            v-if="
                                packingList.packages &&
                                    packingList.packages.length > 0
                            "
                        >
                            <v-col
                                cols="12"
                                xl="6"
                                lg="6"
                                md="6"
                                sm="6"
                                class="py-3"
                            >
                                <v-combobox
                                    v-model="packingList.selectedWorkOrders"
                                    :items="projectWorkOrders"
                                    item-text="code"
                                    label="WorkOrders"
                                    prepend-icon="mdi-hammer-wrench"
                                    multiple
                                    chips
                                    small-chips
                                    readonly
                                    hide-details
                                ></v-combobox>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="6"
                                lg="6"
                                md="6"
                                sm="6"
                                class="py-3"
                            >
                                <v-combobox
                                    v-model="packingList.selectedBOMs"
                                    :items="projectBOMs"
                                    item-text="code"
                                    label="BOMs"
                                    prepend-icon="mdi-list-box-outline"
                                    multiple
                                    chips
                                    small-chips
                                    readonly
                                    hide-details
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-form>
                <v-row ref="files" class="px-3 pt-5">
                    <v-col cols="12" xl="4" lg="4" class="py-0">
                        <div class="d-flex my-2">
                            <div class="d-flex align-center pr-1">
                                <strong>ATTACHED: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="grey lighten-3"
                                    elevation="0"
                                    x-small
                                    class="ma-1 text-capitalize grey--text text--darken-1  "
                                    v-for="(file, i) in packingList.attachments"
                                    :key="i"
                                >
                                    <v-icon
                                        class="mr-1"
                                        color="primary"
                                        :style="{ 'font-size': '17px' }"
                                    >
                                        mdi-paperclip
                                    </v-icon>
                                    <span
                                        @click="openFileAttached(file)"
                                        :style="{
                                            'font-size': '11px',
                                            color: '#2b81d6',
                                            'text-decoration': 'underline',
                                            cursor: 'pointer',
                                        }"
                                        >{{ file }}</span
                                    >
                                    <v-icon
                                        @click="deleteFile(file)"
                                        class="ml-1"
                                        small
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    @click="onButtonClick"
                                    depressed
                                    fab
                                    x-small
                                    color="primary"
                                    class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <input
                                    ref="uploader2"
                                    class="d-none"
                                    type="file"
                                    @change="onFileChanged"
                                />
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="py-2 ma-0">
                    <v-spacer />
                    <v-btn
                        rounded
                        color="primary"
                        @click="openCreationDialog"
                        class="mx-2"
                        :disabled="loading"
                    >
                        <v-icon>
                            mdi-plus
                        </v-icon>
                        NEW PACKAGE
                    </v-btn>
                </v-row>
                <v-container fluid px-0>
                    <v-row>
                        <v-col
                            cols="12"
                            v-for="(packageElement,
                            packageIndex) in packingList.packages"
                            :key="packageIndex"
                            class="mb-5"
                        >
                            <v-data-table
                                :headers="headers"
                                :items="packageElement.items"
                                class="elevation-0"
                                :mobile-breakpoint="0"
                                disable-pagination
                                hide-default-footer
                                multiple-expand
                                :expanded.sync="packageElement.items"
                            >
                                <template v-slot:top>
                                    <v-row
                                        no-gutter
                                        :style="{
                                            'background-color': '#eeeeee',
                                        }"
                                        class="mt-0 mx-0"
                                    >
                                        <v-col
                                            cols="12"
                                            :style="{
                                                display: 'flex',
                                                flexWrap: $vuetify.breakpoint
                                                    .smAndDown
                                                    ? 'wrap'
                                                    : 'nowrap',
                                            }"
                                        >
                                            <h2
                                                class="mt-1 pl-2"
                                                :style="{
                                                    'min-width': 'fit-content',
                                                }"
                                            >
                                                Package
                                                {{ packageIndex + 1 }}
                                            </h2>
                                            <h4
                                                class="d-flex align-end pl-2 pb-1"
                                                :style="{
                                                    'min-width': 'fit-content',
                                                }"
                                            >
                                                {{
                                                    `(W${
                                                        packageElement
                                                            .packageDimensions
                                                            .width
                                                    } x H${
                                                        packageElement
                                                            .packageDimensions
                                                            .height
                                                    } x L${
                                                        packageElement
                                                            .packageDimensions
                                                            .depth
                                                    } ${
                                                        packageElement
                                                            .packageDimensions
                                                            .units
                                                    }) - Crate Mass: ${
                                                        packageElement.packageDimensions &&
                                                        packageElement
                                                            .packageDimensions
                                                            .mass
                                                            ? packageElement
                                                                  .packageDimensions
                                                                  .mass
                                                            : 0
                                                    } Kg -  BOM:${
                                                        packageElement.packageBOM
                                                            ? packageElement.packageBOM
                                                            : ''
                                                    }`
                                                }}
                                            </h4>
                                            <v-spacer />
                                            <v-btn
                                                small
                                                icon
                                                @click="
                                                    printPackage(
                                                        packageElement,
                                                        packageIndex + 1
                                                    )
                                                "
                                            >
                                                <v-icon>mdi-printer-pos</v-icon>
                                            </v-btn>
                                            <v-btn
                                                small
                                                icon
                                                @click="
                                                    openEditDialog(
                                                        packageElement
                                                    )
                                                "
                                            >
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn
                                                small
                                                icon
                                                @click="
                                                    openDeleteDialog(
                                                        packageElement
                                                    )
                                                "
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template
                                    v-slot:[`item.finishAndUCCode`]="{ item }"
                                >
                                    <p class="my-0">
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on }">
                                                <span
                                                    v-on="on"
                                                    style="cursor: pointer;"
                                                >
                                                    {{
                                                        replaceItem(
                                                            item,
                                                            'description'
                                                        )
                                                    }}
                                                </span>
                                            </template>
                                            <span>
                                                {{ replaceItem(item, 'code') }}
                                            </span>
                                        </v-tooltip>
                                    </p>
                                </template>
                                <!--FOOTER-->
                                <template v-slot:[`item.mass`]="{ item }">
                                    <p class="my-0">{{ item.mass }} Kg</p>
                                </template>
                                <template
                                    v-slot:[`item.packingMass`]="{ item }"
                                >
                                    <p class="my-0">
                                        {{
                                            (
                                                item.mass * item.packingQty
                                            ).toFixed(2)
                                        }}
                                        Kg
                                    </p>
                                </template>
                                <template v-slot:[`item.type`]="{ item }">
                                    <p class="my-0">
                                        {{
                                            item['bom'] != undefined
                                                ? 'BOM'
                                                : 'WorkOrder'
                                        }}
                                    </p>
                                </template>
                                <template
                                    v-slot:[`item.suggestedMass`]="{ item }"
                                >
                                    <p class="my-0">
                                        {{
                                            (
                                                item.mass *
                                                Number(item.suggestedQty)
                                            ).toFixed(2)
                                        }}
                                        Kg
                                    </p>
                                </template>
                                <template v-slot:[`item.code`]="{ item }">
                                    <p class="my-0">
                                        {{ item.workOrder || item.bom }}
                                    </p>
                                </template>
                                <template v-slot:[`item.partNumber`]="{ item }">
                                    <p class="my-0">
                                        {{ item.partNumber || item.code }}
                                    </p>
                                </template>
                                <template v-slot:[`body.append`]>
                                    <tr
                                        :style="{
                                            'background-color': '#eeeeee',
                                        }"
                                    >
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td />
                                        <td
                                            v-if="suggestedProcess"
                                            class="font-weight-bold"
                                            :style="{ 'text-align': 'center' }"
                                        >
                                            {{
                                                workOrderTotalSuggested(
                                                    packageElement
                                                )
                                            }}
                                        </td>
                                        <td />
                                        <td
                                            class="font-weight-bold"
                                            :style="{ 'text-align': 'center' }"
                                        >
                                            {{
                                                workOrderTotalPacking(
                                                    packageElement
                                                )
                                            }}
                                        </td>
                                        <td
                                            class="font-weight-bold"
                                            :style="{ 'text-align': 'center' }"
                                        >
                                            {{
                                                workOrderTotalMass(
                                                    packageElement
                                                ).toFixed(2)
                                            }}
                                            Kg
                                        </td>
                                        <td />
                                        <td />
                                        <td />
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
                <v-row
                    no-gutters
                    :style="{ 'background-color': '#eeeeee' }"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="1" lg="1" md="1">
                        <p class="my-2 font-weight-black ml-4">TOTALS</p>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <p class="my-2  ml-4 font-weight-black">
                            QUANTITY OF PACKAGES:
                            {{ packingList.packages.length }}
                        </p>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <p class="my-2  ml-4 font-weight-black">
                            QUANTITY OF ITEMS: {{ totalItemsQty() }}
                        </p>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3">
                        <p class="my-2  ml-4 font-weight-black">
                            NET MASS: {{ netMass().toFixed(2) }} Kg
                        </p>
                    </v-col>
                    <v-col cols="12" xl="2" lg="2" md="2">
                        <p class="my-2  ml-4 font-weight-black">
                            GROSS MASS: {{ grossMass().toFixed(2) }} Kg
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions" class="my-2">
                <v-spacer />
                <v-btn
                    color="primary"
                    rounded
                    class="mt-n2 mr-3 white--text"
                    :loading="loading"
                    @click="buttonActions()"
                    :disabled="!valid || packingList.packages.length == 0"
                    v-if="!packingList.id"
                >
                    CREATE
                </v-btn>
                <v-btn
                    color="primary"
                    rounded
                    class="mt-n2 mr-3 white--text"
                    :loading="loading"
                    @click="buttonActions()"
                    v-if="!onlySuggested && originalOrder && originalOrder.code"
                    :disabled="
                        !valid ||
                            packingList.packages.length == 0 ||
                            qtyValidation()
                    "
                >
                    MARK AS PENDING
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--PACKAGE FORM-->
        <v-dialog
            v-model="packageForm"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <PackageForm
                v-if="packageForm"
                :projectWorkOrders="projectWorkOrders"
                :BOMs="projectBOMs"
                :measureUnits="measureUnits"
                :editionView="editionView"
                :originalPackage="selectedPackage"
                :packingList="packingList"
                :onlySuggested="onlySuggested"
                @close="closePackageForm"
                @addPackage="addPackage"
                @replacePackage="replacePackage"
            />
        </v-dialog>
        <!-- DELETE DIALOG -->
        <v-dialog
            v-model="deleteDialog"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeDeleteDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        Delete Package
                        {{ selectedPackage ? selectedPackage.index : '' }}
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    Are you sure you want to delete this package?
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="pb-4">
                    <v-btn
                        @click="closeDeleteDialog"
                        color="primary"
                        :loading="loading"
                        text
                    >
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deletePackage"
                        color="error"
                        :loading="loading"
                        rounded
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--DeliveryInfo-->
        <v-dialog persistent max-width="500px" v-model="deliveryInfoDialog">
            <DeliveryInfoForm
                v-if="deliveryInfoDialog"
                :packingList="packingList"
                :clientId="project.client"
                @replaceDeliveryInfo="setDeliveryInfo"
                @closeDialog="closeDeliveryInfoDialog"
            />
        </v-dialog>
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedPackingListTable
                v-if="rejectionTable"
                :items="packingList.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Scopes Select-->
        <v-dialog persistent max-width="500px" v-model="openScopeSelection">
            <ScopesSelect
                v-if="openScopeSelection"
                :wos="scopesWO"
                @print="print"
                @closeDialog="openScopeSelection = false"
            />
        </v-dialog>
        <!--Error Alert-->
        <Errors />
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import _ from 'lodash'
import { printPackingPDF } from '@/components/Packing/packingPDF.js'
import { printPackagePDF } from './packagePDF'

export default {
    name: 'PackingForm',
    props: {
        project: {
            type: Object,
            required: true,
            default: () => {},
        },
        originalOrder: {
            type: Object,
            required: false,
            default: () => {},
        },
        onlySuggested: {
            type: Boolean,
            required: true,
            default: () => true,
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        PackageForm: () => import('@/components/Packing/PackageForm.vue'),
        RejectedPackingListTable: () =>
            import('@/components/Packing/RejectedPackingListTable.vue'),
        DeliveryInfoForm: () =>
            import('@/components/Packing/DeliveryInfoForm.vue'),
        ScopesSelect: () => import('@/components/Packing/ScopesSelect.vue'),
    },
    data: () => ({
        packingList: { packages: [] },
        companyId: JSON.parse(localStorage.getItem('company')),
        loading: false,
        packageForm: false,
        projectWorkOrders: [],
        projectBOMs: [],
        headers: [
            {
                text: 'WORK ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },

            {
                text: 'ITEM CODE',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'UNIT MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING QTY',
                value: 'packingQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING MASS',
                value: 'packingMass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'OC',
                value: 'oc',
                align: 'left',
                sortable: false,
            },
            {
                text: 'POSITION',
                value: 'costPosition',
                align: 'left',
                sortable: false,
            },
        ],
        suggestedHeaders: [
            {
                text: 'WORK ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TYPE',
                value: 'type',
                align: 'center',
                sortable: false,
            },

            {
                text: 'ITEM CODE',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'UNIT MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SUGGESTED QTY',
                value: 'suggestedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING MASS',
                value: 'suggestedMass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'OC',
                value: 'oc',
                align: 'left',
                sortable: false,
            },
            {
                text: 'POSITION',
                value: 'costPosition',
                align: 'left',
                sortable: false,
            },
        ],
        workOrders: [],
        height: 350,
        valid: false,
        menu: false,
        selectedPackage: null,
        settingFinish: undefined,
        deleteDialog: false,
        editionView: null,
        selectedWorkOrders: [],
        selectedBOMs: [],
        measureUnits: [],
        localAttachments: [],
        selectedFile: null,
        selectedProcess: { id: 'readyForPacking', name: 'Ready for packing' },
        packingProcess: { id: 'packing', name: 'Packing' },
        minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        createdBy: null,
        users: [],
        rejectionTable: false,
        deliveryInfoDialog: false,
        deliveryInfoString: '',
        enableFocus: true,
        suggestedProcess: false,
        openScopeSelection: false,
        scopesWO: null,
    }),

    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            if (this.onlySuggested) {
                this.headers = this.suggestedHeaders
            } else if (this.originalOrder) {
                this.suggestedProcess = this.originalOrder.packages.some(
                    packageElement =>
                        packageElement.items.some(item => item.suggestedQty)
                )

                if (this.suggestedProcess) {
                    const suggestedHeader = {
                        text: 'SUGGESTED QTY',
                        value: 'suggestedQty',
                        align: 'center',
                        sortable: false,
                    }
                    this.headers.splice(4, 0, suggestedHeader)
                }
            }

            if (this.originalOrder) {
                this.packingList = _.cloneDeep(this.originalOrder)
                this.createdBy = this.findUserName(this.packingList.createdBy)
                if (this.packingList.deliveryInformation) {
                    this.deliveryInfoString =
                        this.packingList.deliveryInformation.location +
                        ' - ' +
                        this.packingList.deliveryInformation.contact +
                        ' - ' +
                        this.packingList.deliveryInformation.phone
                }
            }
            const {
                data: { settings },
            } = await API.getSettings()
            const settingIndex = settings.findIndex(u => u.name === 'Quotes')
            this.settingFinish = settings.find(s => s.name == 'FinishAndUCCode')
            if (settingIndex > -1) {
                this.measureUnits = settings[settingIndex].measureUnits
                    .filter(u => u.measure == 'length')
                    .map(unit => unit.abbr)
            }

            this.projectWorkOrders = await API.getWorkOrdersByProject({
                projectId: this.project.id,
                includeClosed: false,
            })
            this.projectBOMs = await API.getBOMsChangelog({
                projectId: this.project.id,
                status: ['approved', 'closed'],
            })

            this.initWorkOrders()
            this.initBOMs()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        initWorkOrders() {
            this.projectWorkOrders.forEach(workOrder => {
                const wo = this.formatItems(workOrder)
                if (wo.items) {
                    wo.items.forEach(item => {
                        const available = this.calculateAvailableQty(item)
                        item.availableQty = available - (item.packingQty || 0)

                        item.availableSuggested =
                            item.quantity -
                            Number(item.suggestedQty || 0) -
                            (item.packedQty || 0)

                        item.suggestedAvailableQty = item.availableSuggested
                    })
                }
            })
        },
        formatItems(workOrder) {
            if (
                !(
                    (workOrder.status == 'closed' ||
                        workOrder.status == 'cancelled') &&
                    !workOrder.partNumbers
                )
            ) {
                delete workOrder.items
                workOrder.items = []
            }
            if (workOrder.partNumbers) {
                Object.keys(workOrder.partNumbers).forEach(partNumberId => {
                    let item = {
                        partNumberId,
                    }
                    Object.keys(workOrder.partNumbers[partNumberId]).forEach(
                        key => {
                            if (key == 'assemblyItems') {
                                let assemblyItems = []
                                Object.keys(
                                    workOrder.partNumbers[partNumberId][key]
                                ).forEach(aiKey => {
                                    assemblyItems.push({
                                        partNumberId: aiKey,
                                        ...workOrder.partNumbers[partNumberId][
                                            key
                                        ][aiKey],
                                    })
                                })
                                item[key] = assemblyItems
                            } else {
                                item[key] =
                                    workOrder.partNumbers[partNumberId][key]
                            }
                        }
                    )
                    workOrder.items.push(item)
                })
            }
            return workOrder
        },
        initBOMs() {
            this.projectBOMs.forEach(bom => {
                if (bom.items) {
                    bom.items.forEach(item => {
                        const available = this.calculateAvailableBOMQty(item)
                        item.availableQty = available - (item.packingQty || 0)

                        item.availableSuggested =
                            item.quantity -
                            Number(item.suggestedQty || 0) -
                            (item.packedQty || 0)
                        item.suggestedAvailableQty = item.availableSuggested
                    })
                }
            })
        },
        close() {
            this.$emit('close')
        },
        replaceItem(item, option) {
            if (item.finishAndUCCode) {
                if (this.settingFinish) {
                    let dataFinishAndUCCode = this.settingFinish.finishes.find(
                        e => e.id == item.finishAndUCCode
                    )
                    if (option == 'description') {
                        return (
                            dataFinishAndUCCode &&
                            dataFinishAndUCCode.description
                        )
                    } else if (option == 'code') {
                        return dataFinishAndUCCode && dataFinishAndUCCode.name
                    }
                }
            }
        },
        async print(scopes) {
            try {
                this.loading = true
                this.packingList.scopes = scopes
                await printPackingPDF(
                    this.packingList,
                    this.project,
                    this.onlySuggested
                )
                this.openScopeSelection = false
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async printPackage(packageElement, index) {
            try {
                this.loading = true
                await printPackagePDF(
                    this.packingList,
                    this.project,
                    packageElement,
                    index
                )
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async createPackingList() {
            try {
                this.loading = true
                let pl = {
                    ...this.packingList,
                    totalMass: this.netMass(),
                    projectId: this.project.id,
                    projectManager: this.project.projectManager,
                }
                pl.useSuggested = this.onlySuggested
                const newPackingList = await API.createPackingList(pl)
                // upload attachments
                if (this.localAttachments && this.localAttachments.length > 0) {
                    for (const file of this.localAttachments) {
                        await saveFile(
                            file,
                            `${this.companyId}/projects/${this.project.id}/packingLists`
                        )
                    }
                }
                this.$emit('addPackingList', newPackingList)
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updateNotes() {
            try {
                if (this.originalOrder) {
                    await API.updatePackingList({
                        projectId: this.packingList.projectId,
                        packingListId: this.packingList.id,
                        notes: this.packingList.notes,
                    })
                    this.$emit('replacePackingList', this.packingList)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async updateShipmentDate() {
            try {
                this.menu = false
                if (this.originalOrder) {
                    await API.updatePackingList({
                        projectId: this.packingList.projectId,
                        packingListId: this.packingList.id,
                        requestedShipmentDate: this.packingList
                            .requestedShipmentDate,
                    })
                    this.$emit('replacePackingList', this.packingList)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        totalItemsQty() {
            if (this.onlySuggested) {
                return this.packingList.packages.reduce(
                    (accumulator, packageInfo) =>
                        accumulator +
                        packageInfo.items.reduce(
                            (itemAccumulator, item) =>
                                itemAccumulator + Number(item.suggestedQty),
                            0
                        ),
                    0
                )
            } else {
                return this.packingList.packages.reduce(
                    (accumulator, packageInfo) =>
                        accumulator +
                        packageInfo.items.reduce(
                            (itemAccumulator, item) =>
                                itemAccumulator + Number(item.packingQty),
                            0
                        ),
                    0
                )
            }
        },
        netMass() {
            if (this.onlySuggested) {
                return this.packingList.packages.reduce(
                    (accumulator, packageInfo) =>
                        accumulator +
                        packageInfo.items.reduce(
                            (itemAccumulator, item) =>
                                itemAccumulator +
                                Number(item.suggestedQty) * item.mass,
                            0
                        ),
                    0
                )
            } else {
                return this.packingList.packages.reduce(
                    (accumulator, packageInfo) =>
                        accumulator +
                        packageInfo.items.reduce(
                            (itemAccumulator, item) =>
                                itemAccumulator + Number(item.packingMass),
                            0
                        ),
                    0
                )
            }
        },
        grossMass() {
            const netMass = this.netMass()
            const cratesMass = this.packingList.packages.reduce(
                (accumulator, packageInfo) =>
                    accumulator + (+packageInfo.packageDimensions.mass || 0),

                0
            )
            return netMass + cratesMass
        },
        closePackageForm() {
            this.selectedPackage = null
            this.editionView = null
            this.packageForm = false
        },
        openCreationDialog() {
            this.editionView = false
            this.packageForm = true
        },
        closeDeleteDialog() {
            this.selectedPackage = null
            this.deleteDialog = false
        },
        openDeleteDialog(packageData) {
            this.selectedPackage = _.cloneDeep(packageData)
            this.deleteDialog = true
        },
        openEditDialog(packageData) {
            this.editionView = true
            this.selectedPackage = packageData
            this.packageForm = true
        },
        addPackage({ newPackage }) {
            this.packingList.packages.push(newPackage)
            this.packingList.totalMass = this.netMass()
            this.closePackageForm()
            this.getSelectedWorkOrders()
            this.getSelectedBOMs()
            this.updateWorkOrders()
            this.updateBOMs()
            this.$emit('replacePackingList', this.packingList)
        },
        async deletePackage() {
            try {
                this.loading = true
                if (this.packingList.id) {
                    await API.deletePackage({
                        projectId: this.packingList.projectId,
                        packingListId: this.packingList.id,
                        packageId: this.selectedPackage.id,
                    })
                }

                const index = this.packingList.packages.findIndex(
                    p => p.id == this.selectedPackage.id
                )
                if (index > -1) {
                    this.packingList.packages.splice(index, 1)
                }

                this.packingList.totalMass = this.netMass()
                this.$emit('replacePackingList', this.packingList)
                this.getSelectedWorkOrders()
                this.getSelectedBOMs()
                this.closeDeleteDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        replacePackage({ packageData }) {
            const index = this.packingList.packages.findIndex(
                p => p.id == this.selectedPackage.id
            )
            this.packingList.packages.splice(index, 1, packageData)
            this.updateWorkOrders()
            this.closePackageForm()
            let totalMass = 0
            if (
                this.packingList.packages &&
                this.packingList.packages.length > 0
            ) {
                this.packingList.packages.forEach(pck => {
                    totalMass += pck.items.reduce(
                        (accum, item) => accum + Number(item.packingMass || 0),
                        0
                    )
                })
            }
            this.packingList.totalMass = totalMass
            this.$emit('replacePackingList', this.packingList)
        },
        workOrderTotalPacking(packageElement) {
            let total = 0
            packageElement.items.forEach(item => {
                if (this.onlySuggested) {
                    total += Number(item.suggestedQty)
                } else {
                    total += Number(item.packingQty)
                }
            })
            return total
        },
        workOrderTotalSuggested(packageElement) {
            return packageElement.items.reduce(
                (itemAccumulator, item) =>
                    itemAccumulator + Number(item.suggestedQty),
                0
            )
        },
        workOrderTotalMass(packageElement) {
            let mass = 0
            packageElement.items.forEach(item => {
                if (this.onlySuggested) {
                    if (item.mass) {
                        mass += Number(item.suggestedQty) * Number(item.mass)
                    }
                } else {
                    if (item.mass) {
                        mass += Number(item.packingQty) * Number(item.mass)
                    }
                }
            })
            return mass
        },
        getSelectedWorkOrders() {
            const workOrdersSet = new Set()
            this.packingList.notes = ''
            this.packingList.packages.forEach(pkg => {
                pkg.items.forEach(item => {
                    if (item.workOrder != undefined) {
                        workOrdersSet.add(item.workOrder)
                    }
                })
            })
            const workOrdersArray = Array.from(workOrdersSet)
            this.packingList.selectedWorkOrders = workOrdersArray.map(
                (code, index) => {
                    const workOrder = this.projectWorkOrders.find(
                        wo => wo.code === code
                    )
                    if (workOrder && workOrder.notes) {
                        this.packingList.notes += workOrder.notes
                        if (index < workOrdersArray.length - 1) {
                            this.packingList.notes += ' - '
                        }
                    }
                    return workOrder
                        ? { code, notes: workOrder.notes }
                        : { code, notes: '' }
                }
            )

            return this.packingList.selectedWorkOrders
        },
        getSelectedBOMs() {
            const bomsSet = new Set()
            this.packingList.notes = ''
            this.packingList.packages.forEach(pkg => {
                pkg.items.forEach(item => {
                    if (item.bom != undefined) {
                        bomsSet.add(item.bom)
                    }
                })
            })
            const bomsArray = Array.from(bomsSet)
            this.packingList.selectedBOMs = bomsArray.map((code, index) => {
                const bom = this.projectBOMs.find(bom => bom.code === code)
                if (bom && bom.notes) {
                    this.packingList.notes += bom.notes
                    if (index < bomsArray.length - 1) {
                        this.packingList.notes += ' - '
                    }
                }
                return bom ? { code, notes: bom.notes } : { code, notes: '' }
            })

            return this.packingList.selectedBOMs
        },
        updateWorkOrders() {
            const items = this.packingList.packages.flatMap(p => p.items)
            const workOrders = this.packingList.selectedWorkOrders.map(
                w => w.code
            )
            this.projectWorkOrders.forEach(wo => {
                //update only the work orders used in PL
                if (workOrders.includes(wo.code)) {
                    const filteredItems = items.filter(
                        item => item.workOrder == wo.code
                    )

                    //update all items in work order
                    wo.items.forEach(i => {
                        const available = this.calculateAvailableQty(i)
                        i.availableQty = available - (i.packingQty || 0)
                        i.availableSuggested =
                            i.quantity -
                            (i.packedQty || 0) -
                            Number(i.suggestedQty || 0)
                        i.suggestedAvailableQty = i.availableSuggested
                    })

                    // update items in the packing list
                    filteredItems.forEach(item => {
                        const index = wo.items.findIndex(
                            woItem => woItem.partNumberId == item.partNumberId
                        )

                        if (index > -1) {
                            wo.items[index].availableQty -= item.packingQty
                            wo.items[index].availableSuggested -= Number(
                                item.suggestedQty
                            )
                            wo.items[index].suggestedAvailableQty =
                                wo.items[index].availableSuggested
                        }
                    })
                }
            })
        },
        updateBOMs() {
            const items = this.packingList.packages.flatMap(p => p.items)
            const boms = this.packingList.selectedBOMs.map(b => b.code)
            this.projectBOMs.forEach(bom => {
                if (boms.includes(bom.code)) {
                    const filteredItems = items.filter(
                        item => item.bom == bom.code
                    )

                    bom.items.forEach(i => {
                        const available = this.calculateAvailableBOMQty(i)
                        i.availableQty = available - Number(i.packingQty || 0)
                        i.availableSuggested =
                            i.quantity -
                            (i.packedQty || 0) -
                            Number(i.suggestedQty || 0)
                        i.suggestedAvailableQty = i.availableSuggested
                    })

                    filteredItems.forEach(item => {
                        const index = bom.items.findIndex(
                            bomItem => bomItem.code == item.code
                        )

                        if (index > -1) {
                            bom.items[index].availableQty -= Number(
                                item.packingQty
                            )
                            bom.items[index].availableSuggested -= Number(
                                item.suggestedQty
                            )
                            bom.items[index].suggestedAvailableQty =
                                bom.items[index].availableSuggested
                        }
                    })
                }
            })
        },
        onButtonClick() {
            this.$refs.uploader2.click()
        },
        async onFileChanged(e) {
            this.selectedFile = e.target.files[0]
            await this.saveFile(this.selectedFile)
        },
        async saveFile(file) {
            try {
                this.loading = true
                if (!this.packingList.status) {
                    this.localAttachments.push(file)
                    if (!this.packingList.attachments) {
                        this.packingList.attachments = [file.name]
                    } else {
                        this.packingList.attachments.push(file.name)
                    }
                    return null
                }
                await saveFile(
                    file,
                    `${this.companyId}/projects/${this.packingList.projectId}/packingLists`
                )
                if (!this.packingList.attachments) {
                    this.packingList.attachments = [file.name]
                } else {
                    this.packingList.attachments.push(file.name)
                }
                await API.packingListAttachments({
                    id: this.packingList.id,
                    projectId: this.packingList.projectId,
                    files: this.packingList.attachments,
                })
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                if (!this.packingList.status) {
                    const localFile = this.localAttachments.find(
                        f => f.name == file
                    )
                    if (localFile) {
                        const url = URL.createObjectURL(localFile)
                        window.open(url, '_blank')
                    }
                    return null
                }
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.packingList.projectId}/packingLists`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteFile(file) {
            try {
                this.loading = true
                if (!this.packingList.status) {
                    this.localAttachments = this.localAttachments.filter(
                        f => f != file
                    )
                    this.packingList.attachments = this.packingList.attachments.filter(
                        f => f != file
                    )
                    return null
                }
                await deleteFile(
                    file,
                    `${this.companyId}/projects/${this.packingList.projectId}/packingLists`
                )
                this.packingList.attachments = this.packingList.attachments.filter(
                    f => f != file
                )
                await API.packingListAttachments({
                    id: this.packingList.id,
                    projectId: this.packingList.projectId,
                    files: this.packingList.attachments,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        buttonActions() {
            if (!this.originalOrder) {
                this.createPackingList()
            } else {
                this.markAsPending()
            }
        },
        calculateAvailableQty(item) {
            let availableQty = 0

            if (item.production) {
                item.production.forEach(production => {
                    if (production.process.id == this.selectedProcess.id) {
                        availableQty += production.qty
                    }
                })
            }
            if (item.deliveries) {
                let received = item.deliveries.filter(
                    register =>
                        register.nextProcess.id == this.packingProcess.id &&
                        register.receivedBy
                )
                availableQty += received.reduce(
                    (total, register) => total + register.qty,
                    0
                )
            }
            return availableQty
        },
        calculateAvailableBOMQty(item) {
            let availableQty = 0
            if (item.deliveries) {
                item.deliveries.forEach(delivery => {
                    availableQty += Number(delivery.qty || 0)
                })
            }
            return availableQty
        },
        findUserName(id) {
            let userName = ''
            const user = this.users.find(u => u.id == id)
            if (user) {
                userName = user.name
            }
            return userName
        },
        async markAsPending() {
            try {
                this.loading = true
                const packingList = await API.markPLAspending({
                    projectId: this.project.id,
                    packingListId: this.originalOrder.id,
                })
                this.$emit('replacePackingList', packingList)
                this.close()
            } catch (error) {
                console.log(error)
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        setDeliveryInfo(deliveryInfo) {
            this.packingList.deliveryInformation = deliveryInfo
            this.deliveryInfoString =
                deliveryInfo.location +
                ' - ' +
                deliveryInfo.contact +
                ' - ' +
                deliveryInfo.phone
            this.$emit('replacePackingList', this.packingList)
        },
        openDeliveryInfoDialog() {
            if (this.enableFocus) {
                this.deliveryInfoDialog = true
                this.$nextTick(() => {
                    this.enableFocus = false
                    this.$refs.deliveryInfoInput.blur()
                })
            }
        },
        closeDeliveryInfoDialog() {
            this.enableFocus = false
            this.deliveryInfoDialog = false
        },
        resetFocus() {
            this.enableFocus = true
        },
        qtyValidation() {
            const validation = this.packingList.packages.every(packageElement =>
                packageElement.items.every(
                    item => item.packingQty && item.packingQty > 0
                )
            )
            return !validation
        },
        selectScopes() {
            let wos = []
            this.packingList.selectedWorkOrders.forEach(wo => {
                const foundWO = this.projectWorkOrders.find(
                    w => w.code == wo.code
                )
                if (foundWO) {
                    wos.push(foundWO)
                }
            })
            this.scopesWO = wos
            this.openScopeSelection = true
        },
    },
}
</script>

<style scoped>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
